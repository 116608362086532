import React from "react";
import moment from "moment";
import MultiClamp from "react-multi-clamp";

/** MUI */
import { alpha } from "@mui/system";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
    postCard: {
        borderRadius: 0,
        backgroundColor: "transparent",
        height: "100%",
        border: `1px solid ${alpha(theme.palette.primary.colors["900"], 1)}`,
    },
    postCardActionArea: {
        display: "flex",
        flexWrap: "wrap",
        height: "100%",
        flexDirection: "column",
    },
    postCardMedia: {
        backgroundColor: theme.palette.primary.main,
        width: "100%",
    },
    postCardContent: {
        margin: theme.spacing(-12, 5, 0, 5),
        paddingBottom: 0,
        // backgroundColor: theme.palette.common.white,
        minHeight: theme.spacing(20),
        flexGrow: 1,
        display: "flex",
        flexWrap: "wrap",

        // backgroundImage: "linear-gradient(rgba(255, 255, 255, 1) 4%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.025) 100%)",
        backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 1) 4%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%)",
        backdropFilter: "blur(2px)",
        // border: `2px solid ${alpha(theme.palette.primary.colors["900"], 0.125)}`,
        minHeight: 240,

        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(-12, 2, 0, 2),
        },
    },
    postCardContentCategoryTitle: {
        // color: theme.palette.primary.colors["900"],
        marginBottom: theme.spacing(2),
        // border: `1px solid ${alpha(theme.palette.primary.colors["900"], 0.925)}`,
        borderRadius: 24,
        padding: theme.spacing(0, 1),
        // textTransform: "uppercase",
        textAlign: "right",
        // fontWeight: 500,
        // display: "inline-block",
        fontSize: "0.875rem",
        "&::first-letter": {
            // fontSize: "120%",
        },
    },
    postCardContentTagsWrap: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: theme.spacing(2),
        flexWrap: "wrap",
        borderTop: `1px solid ${alpha(theme.palette.primary.colors["900"], 0.125)}`,
    },
    postCardContentTag: {
        // color: theme.palette.secondary.colors["900"],
        color: theme.palette.common.white,
        margin: theme.spacing(1, 1, 0, 0),
        // border: `1px solid ${alpha(theme.palette.secondary.colors["900"], 0.25)}`,
        // backgroundColor: `${alpha(theme.palette.secondary.colors["900"], 0.925)}`,
        backgroundColor: `${alpha(theme.palette.secondary.main, 1)}`,
        borderRadius: 24,
        padding: theme.spacing(0, 1),
        // textTransform: "uppercase",
        textAlign: "right",
        fontWeight: 400,
        // display: "inline-block",
        fontSize: "0.75rem",
        "&::first-letter": {
            // fontSize: "120%",
        },
    },
    postCardContentTitle: {
        color: theme.palette.common.black,
        marginBottom: theme.spacing(2),
        borderBottom: `2px solid ${alpha(theme.palette.primary.colors["900"], 0.125)}`,
        // textTransform: "uppercase",
        textAlign: "left",
        fontWeight: 500,
        "&::first-letter": {
            fontSize: "120%",
        },
    },
    postCardContentDesc: {
        color: theme.palette.common.black,
        flexGrow: 1,
    },
}));

const PostCard = (props) => {
    const { post, large } = props;
    const classes = useStyles();
    return (
        <Card className={classes.postCard} elevation={0}>
            <CardActionArea href={`/blog/${post.id}`} className={classes.postCardActionArea}>
                <Box
                    component="div"
                    sx={{
                        height: large ? { xs: 200, md: 250 } : 200,
                        backgroundImage: `url("${post.image_url}")`,
                        backgroundSize: "cover",
                        backgroundPositionX: "center",
                        backgroundPositionY: "top",
                    }}
                    alt={post.title}
                    className={classes.postCardMedia}
                />
                <CardContent className={classes.postCardContent}>
                    <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant={"body2"} component="span">
                            {moment(new Date(post.updatedAt)).format("MMMM DD, YYYY")}
                        </Typography>
                        <Typography variant={"body2"} component="span" className={classes.postCardContentCategoryTitle}>
                            {post.categoryName}
                        </Typography>
                    </Box>
                    <Typography variant="h5" component="div" className={classes.postCardContentTitle}>
                        <MultiClamp ellipsis={"..."} clamp={1}>
                            {post.title}
                        </MultiClamp>
                        {/* {post.title} */}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" component={'span'} className={classes.postCardContentDesc}>
                        <MultiClamp ellipsis={"..."} clamp={4}>
                            {post.description}
                        </MultiClamp>
                        {/* {post.description?.slice(0, 240)} */}
                        {/* {post.description?.length > 240 ? "..." : ""} */}
                    </Typography>
                    <Box className={classes.postCardContentTagsWrap}>
                        {post?.tags
                            ?.sort(() => Math.random() - 0.5)
                            // ?.slice(0, 4)
                            ?.map((tag) => {
                                return (
                                    <Typography variant={"caption"} key={tag} className={classes.postCardContentTag}>
                                        {tag}
                                    </Typography>
                                );
                            })}
                    </Box>
                </CardContent>
                <CardActions></CardActions>
            </CardActionArea>
        </Card>
    );
};

const LatestPosts = (props) => {
    const { posts } = props;
    const classes = useStyles();
    return (
        <>
            {posts ? (
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <PostCard post={posts[0]} large={true} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PostCard post={posts[1]} large={true} />
                    </Grid>
                    {posts?.length > 2 ? (
                        <>
                            {posts.slice(2, 5)?.map((post) => {
                                return (
                                    <Grid item xs={12} md={4} key={post.id}>
                                        <PostCard post={post} />
                                    </Grid>
                                );
                            })}
                        </>
                    ) : (
                        <></>
                    )}
                </Grid>
            ) : (
                <></>
            )}
        </>
    );
};

export default LatestPosts;
